import React from "react";
import { showNotification } from "@mantine/notifications";
import { X, Check } from "tabler-icons-react";

export function getUrl(url, successCallback, expectBlob) {
	fetchUrl(url, "GET", null, null, successCallback, null, null, null, expectBlob);
}
export function downloadFile(url, fileName) {
	getUrl(url, blob => {
		const url = window.URL.createObjectURL(new Blob([blob]));
		const link = document.createElement("a");
		link.href = url;
		link.download = fileName;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}, true);
}
export function fetchUrl(url, method, body, stateAction, successCallback, successMessage, errorCallback, errorMessage, expectBlob) {
	const truncate = (str, n) => {
		return (str?.length > n) ? str.substr(0, n - 1) + "..." : str;
	};
	const isJson = str => {
		try { JSON.parse(str); } catch (e) { return false; }
		return true;
	};

	const handleError = (err, status) => {
		stateAction && stateAction(false);
		errorCallback && errorCallback();

		// load language pack to show error message
		const lang = JSON.parse(localStorage.getItem("user"))?.lang || "en";
		import(`i18n/lang.${lang}.json`).then(dict => {
			if (status === 404) errorMessage = dict.fetch.notFound;
			if (status === 401)
				return window.dispatchEvent(new CustomEvent("logout", { detail: { reason: "tokenExpired" } }));
			if (status === 403) errorMessage = dict.fetch.forbidden;
			errorMessage = errorMessage || dict.fetch.unexpectedError;

			const jsonErr = isJson(err?.message) ? JSON.parse(err.message) : (isJson(err) ? JSON.parse(err) : null);
			showNotification({
				icon: <X size={18} />, color: "red", title: "Error",
				message: jsonErr?.title
					|| `${errorMessage} ${(truncate(err?.message, 200) || "")}`
			});
		});
	};
	const handleSuccess = data => {
		stateAction && stateAction(false);
		successCallback && successCallback(data);
		if (successMessage)
			showNotification({
				icon: <Check size={18} />, color: "teal", message: successMessage
			});
	};

	stateAction && stateAction(true);

	let headers = new Headers({ Authorization: `Bearer ${localStorage.getItem("token")}` });
	if (isJson(body)) headers.append("Content-Type", "application/json");
	const userToken = JSON.parse(sessionStorage.getItem("userLogged"))?.token;
	if (userToken) headers.append("userToken", userToken);

	fetch(`${process.env.REACT_APP_API_URL}${url}`, {
		method: method || "GET", body: body || undefined, headers: headers
	}).then(res => {
		return res.ok
			? (res.status == 204 // empty result
				? handleSuccess()
				: (expectBlob 
					? res.blob().then(blob => handleSuccess(blob))
					: res.json().then(data => handleSuccess(data))))
			: res.text().then(text => handleError(text, res.status));
	}).catch(err => {
		console.log(err);
		handleError();
	});
}