import React, { useEffect, useState } from "react";
import useConversation from "hooks/useConversation";
import { getUrl } from "utils/urlHelpers";
import { Button, Paper } from "@mantine/core";
import TablePlaceHolder from "components/TablePlaceHolder";
import { ArrowLeft } from "tabler-icons-react";

export default function TemplateLogDetail({ dict, logUid, setLogUid }) {
	const formatMessage = useConversation();
	const [messages, setMessages] = useState(null);

	useEffect(() => {
		if (!logUid) return;
		getUrl(`chatHistory/${logUid}`, setMessages);
	}, [logUid]);

	return (
		<>
			<Button compact onClick={() => setLogUid(null)} leftIcon={<ArrowLeft />}>{dict.back}</Button>
			{!messages
				? <TablePlaceHolder row={5} col={3} />
				: messages?.map(message =>
					<div key={message.id}>
						<Paper p="md" radius={12}>
							{message.question}
						</Paper>
						<Paper key={message.id} p="md" radius={12} sx={{ backgroundColor: "black" }}>
							{formatMessage(message.answer)}
						</Paper>
					</div>
				)}
		</>

	);
}