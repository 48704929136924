import React, { useEffect, useState } from "react";
import { Readability } from "@mozilla/readability";
import { fetchUrl } from "utils/urlHelpers";
import { showNotification } from "@mantine/notifications";
import { X } from "tabler-icons-react";

export default function useScrape(setSections) {
	const [extractUrlError, setExtractUrlError] = useState(null);

	useEffect(() => {
		if (extractUrlError) {
			showNotification({
				message: extractUrlError, color: "red", icon: <X />,
			});
		}
	}, [extractUrlError]);

	const extractUrlMainContent = (url, setProcessing) => {
		const fetchContent = async () => {
			try {
				fetchUrl(`template/web?url=${encodeURIComponent(url)}`, null, null, setProcessing, parseHtml);
			} catch (error) {
				setExtractUrlError(error);
			}
		};
		fetchContent();
	};

	const parseHtml = data => {
		if (data.error) return setExtractUrlError(data.error);
		const dom = new DOMParser().parseFromString(data.html, "text/html");
		const article = new Readability(dom).parse();

		const text = removeNavAndHandleIframe(article.content)
			.replace(/\t/g, "") 					// remove tabs
			.split(/\n\s*\n\s*\n\s*\n\s*/) 			// split by 4 new lines
			.filter(x => x.length > 175) 			// remove short paragraphs
			.map(x => x.replace(/ {2,}/g, " ")); 	// remove double spaces
		
		setSections(text);
	};

	// Removes nav elements from the page and replaces iframes with their src.
	const removeNavAndHandleIframe = html => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(html, "text/html");
		const page = doc.querySelector(".page");
		page.querySelectorAll("nav").forEach(nav => nav.parentElement.remove());
		page.querySelectorAll("iframe").forEach(iframe =>
			iframe.parentNode.replaceChild(document.createTextNode(iframe.getAttribute("src")), iframe)
		);
		return page.innerText;
	};

	return { extractUrlMainContent };
}