import { Button, Group, Stack, Table, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { ArrowLeft, Plus, Trash } from "tabler-icons-react";
import { fetchUrl, getUrl } from "utils/urlHelpers";

export default function AdminTemplateLibrary({ dict, setLibraryOpened, templates, setSavedTemplate, setDeletedTemplate }) {
	const [library, setLibrary] = useState(null);
	const [updatingIds, setUpdatingIds] = useState([]);

	useEffect(() => {
		if (!library)
			getUrl("templateCustomer", setLibrary);
	}, []);

	const templateIsInLibrary = libraryTemplate => {
		return templates?.findIndex(template => template.id === libraryTemplate.id) >= 0;
	};

	const addToLibrary = template => {
		setUpdatingIds(prev => [...prev, template.id]);
		fetchUrl(`templateCustomer/${template.id}`, "POST", null, null, () => {
			setUpdatingIds(prev => prev.filter(id => id !== template.id));
			setSavedTemplate(template);
		});
	};
	const removeFromLibrary = template => {
		setUpdatingIds(prev => [...prev, template.id]);
		fetchUrl(`templateCustomer/${template.id}`, "DELETE", null, null, () => {
			setUpdatingIds(prev => prev.filter(id => id !== template.id));
			setDeletedTemplate(template);
		});
	};

	const rows = library?.map(template => (
		<tr key={template.id}>
			<td style={{ textAlign: "center" }}>
				{templateIsInLibrary(template)
					? <Button compact color="red" onClick={() => removeFromLibrary(template)} leftIcon={<Trash size={14} />} loading={updatingIds.includes(template.id)}>
						{dict.remove}
					</Button>
					: <Button compact color="green" onClick={() => addToLibrary(template)} leftIcon={<Plus size={14} />} loading={updatingIds.includes(template.id)}>
						{dict.add}
					</Button>
				}
			</td>
			<td>{template.category || dict.miscellaneous}</td>
			<td>{template.name}</td>
			<td>{template.intro}</td>
		</tr>
	));

	return (
		<Stack>
			<Group position="apart">
				<Text size="lg" weight={500}>{dict.library}</Text>
				<Button onClick={() => setLibraryOpened(false)} leftIcon={<ArrowLeft />}>{dict.back}</Button>
			</Group>
			<Table highlightOnHover>
				<thead>
					<tr>
						<th></th>
						<th>{dict.category}</th>
						<th>{dict.name}</th>
						<th>{dict.description}</th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</Table>
			<Group position="right">
				<Button onClick={() => setLibraryOpened(false)} leftIcon={<ArrowLeft />}>{dict.back}</Button>
			</Group>
		</Stack>
	);
}