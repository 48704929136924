import React, { useState } from "react";
import ChartsContainer from "../charts/ChartsContainer";

export default function Dashboard() {
	const [charts, setCharts] = useState([
		{ index: 1, type: 1, chartType: "ColumnChart" }, { index: 2, type: 2, chartType: "ColumnChart" },
		{ index: 3, type: 3 }, { index: 4, type: 4 }, { index: 5, type: 5, chartType: "PieChart" }, 
		{ index: 6, type: 6, chartType: "Table" }
	]);

	return <ChartsContainer charts={charts} setCharts={setCharts} />;
}