import { Button, MultiSelect, Select, Stack, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { Plus } from "tabler-icons-react";
import { emailToName } from "utils/helpers";
import { getUrl } from "utils/urlHelpers";

export default function TemplateRights({ dict, userRights, setUserRights }) {
	const [users, setUsers] = useState(null);

	useEffect(() => {
		loadUsers();
	}, [userRights]);

	const loadUsers = () => {
		if (users || userRights.length == 0) return;
		getUrl("user", (users) => setUsers(users.map(x => ({ value: x.id + "", label: emailToName(x.email) }))));
	};

	const getRow = i => {
		return <tr key={i}>
			<td>
				<Select value={userRights[i].userId}
					onChange={userId => setUserRights(userRights.map((x, j) => j == i ? { ...x, userId } : x))}
					placeholder={dict.user} data={users} searchable />
			</td>
			<td>
				<MultiSelect value={userRights[i].rights} placeholder={dict.rights}
					onChange={rights => setUserRights(userRights.map((x, j) => j == i ? { ...x, rights } : x))}
					data={[{ value: "1", label: dict.rightAdmin }, { value: "2", label: dict.rightLog }]} />
			</td>
			<td>
				<Button variant="outline" compact color="red" onClick={() => setUserRights(userRights.filter((x, j) => j != i))}>{dict.delete}</Button>
			</td>
		</tr>;
	};

	return (
		<Stack align="flex-start" spacing={0} >
			<Text weight={500} size="sm">{dict.userRights}</Text>
			{users &&
				<table>
					<tbody>
						{userRights.map((x, i) => getRow(i))}
					</tbody>
				</table>}
			<Button variant="outline" color="blue" leftIcon={<Plus size={18} />}
				onClick={() => setUserRights([...userRights, {}])}>{dict.addRights}</Button>
		</Stack>
	);
}