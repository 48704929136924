import React, { useEffect, useState } from "react";
import { Button, Indicator, Paper, SimpleGrid, Stack, Text } from "@mantine/core";
import { Template as TemplateIcon } from "tabler-icons-react";
import Templates from "pages/template/templates";
import Warning from "pages/warning";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import TemplateContent from "pages/template/templateContent";

export default function ConversationEmpty({ dict, askQuestion, largeScreen, favourites, setFavourites }) {
	const navigate = useNavigate();
	const location = useLocation();
	const [warningOpened, setWarningOpened] = useState(!localStorage.getItem("warningRead"));
	const params = useParams();

	useEffect(() => {
		if (!params.templateId)
			document.title = "BigGPT";
		
	}, [params.templateId]);
	const favouriteTemplates = favourites?.map(f => (
		<Button key={f.id} variant="light" compact onClick={() => navigate(`templates/${f.id}`)} sx={{ width: "100%" }}>{f.name}</Button>
	));

	return (
		<Paper sx={{ flexGrow: 1 }}>
			<Stack align="center" justify="space-between" sx={{ height: "100%" }}>
				<span></span>
				<Stack align="center" spacing={0}>
					<Indicator label="Plus" size={16} offset={-8} position="middle-end">
						<Text size="xl" weight={700}>BigGPT &nbsp;</Text>
					</Indicator>
					<Button variant="light" mt="sm" mb={48} onClick={() => navigate("/templates")} leftIcon={<TemplateIcon strokeWidth={1.5} size={22} />}>
						{dict.template}
					</Button>
					{favouriteTemplates?.length > 0
						? <>
							<Text size="lg" mb="sm">{dict.favourites}</Text>
							<SimpleGrid cols={Math.min(favouriteTemplates?.length, largeScreen ? 5 : 2)}>
								{favouriteTemplates}
							</SimpleGrid>
						</>
						: <Text size="lg" mb="sm">{dict.noFavourites}</Text>
					}
				</Stack>
				<Stack align="center" spacing="xs">
					<Text italic size="sm">{dict.enterQuestion}</Text>
					<Text italic size="sm">{dict.warning3b}</Text>
				</Stack>
			</Stack>
			{location.pathname == "/templates" &&
				<Templates dict={dict} largeScreen={largeScreen} />
			}
			{params.templateId &&
				<TemplateContent dict={dict} templateId={params.templateId} largeScreen={largeScreen}
					favourites={favourites} setFavourites={setFavourites}
					applyTemplate={(prompt, templateId, files) => { askQuestion(null, prompt, null, templateId, files); navigate("/"); }}
				/>
			}

			{warningOpened && <Warning dict={dict} largeScreen={largeScreen} setWarningOpened={setWarningOpened} />}
		</Paper>
	);
}