import React, { useState } from "react";
import { Button, Group, Menu, Modal, Text, Textarea } from "@mantine/core";
import { Check, Dots, ExclamationMark, Puzzle, QuestionMark, Send, Speakerphone, X } from "tabler-icons-react";
import { fetchUrl } from "utils/urlHelpers";
import { objectToFormData } from "utils/helpers";
import { showNotification } from "@mantine/notifications";

export default function ConversationFeedback({ conversation }) {
	const [feedbackType, setFeedbackType] = useState(null);
	const [feedBackDetails, setFeedBackDetails] = useState(null);
	const [sending, setSending] = useState(null);

	const openFeedbackPopup = issue => {
		setFeedbackType(issue.target.innerText);
	};

	const sendFeedback = () => {
		const data = {
			type: feedbackType,
			details: feedBackDetails,
		};
		const url = `chatHistory/${conversation[conversation.length - 1].id}/feedback`;
		fetchUrl(url, "POST", objectToFormData(data), setSending, () => {
			setFeedbackType(null);
			setFeedBackDetails(null);
			showNotification({
				icon: <Check size={18} />, color: "teal", message: "Thank you for your feedback 😀"
			});
		});
	};

	return (
		<>
			<Menu shadow="md" width={200}>
				<Menu.Target>
					<Button variant="outline" color="orange" leftIcon={<Speakerphone size={18} />}>
						Beta Explorer Feedback
					</Button>
				</Menu.Target>

				<Menu.Dropdown>
					<Menu.Label>Report issue on bot’s answer</Menu.Label>
					<Menu.Item onClick={openFeedbackPopup} icon={<ExclamationMark size={18} />}>Should know the answer</Menu.Item>
					<Menu.Item onClick={openFeedbackPopup} icon={<QuestionMark size={18} />}>Didn’t get the question</Menu.Item>
					<Menu.Item onClick={openFeedbackPopup} icon={<X size={18} />}>Incorrect answer</Menu.Item>
					<Menu.Item onClick={openFeedbackPopup} icon={<Puzzle size={18} />}>Incomplete answer</Menu.Item>
					<Menu.Item onClick={openFeedbackPopup} icon={<Dots size={18} />}>Something else</Menu.Item>
				</Menu.Dropdown>
			</Menu>
			<Modal opened={feedbackType != null} onClose={() => setFeedbackType(null)} title={feedbackType}>
				<Textarea label="More details if needed" value={feedBackDetails} onChange={e => setFeedBackDetails(e.target.value)} maxLength={500} />
				<Text size="xs" mt="lg">
					By submitting this feedback you agree the current conversation will be looked at by a human in order to improve the bot.<br/>
					No other conversation, past or future will ever be looked at without your explicit consent.
				</Text>
				<Group position="right" mt="xl">
					<Button variant="outline" color="gray" onClick={() => setFeedbackType(null)} leftIcon={<X size={18} />}>
						Cancel
					</Button>
					<Button variant="outline" onClick={sendFeedback} leftIcon={<Send size={18} />} loading={sending}>
						Send
					</Button>
				</Group>
			</Modal>
		</>
	);
}