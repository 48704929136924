import { ActionIcon, Button, Group, Modal, TextInput } from "@mantine/core";
import React, { useState } from "react";
import { X } from "tabler-icons-react";

export default function HistoryRename({ dict, conversation, updateConversation, setVisible }) {
	const [title, setTitle] = useState(conversation.title);

	const handleSubmit = e => {
		e.preventDefault();
		updateConversation(conversation, false, title);
	};

	return (
		<Modal opened onClose={() => setVisible(false)} title={dict.renameConversation}>
			<form onSubmit={handleSubmit}>
				<TextInput data-autofocus value={title} onChange={e => setTitle(e.target.value)} required
					rightSection={<ActionIcon size="xs" onClick={() => setTitle("")}><X /></ActionIcon>} />
				<Group position="right" mt="xl">
					<Button variant="outline" onClick={() => setVisible(null)}>{dict.cancel}</Button>
					<Button color="teal" type="submit">{dict.save}</Button>
				</Group>
			</form>
		</Modal>
	);
}