import React from "react";
import { Button, Group, Modal, Text } from "@mantine/core";
import { AlertTriangle } from "tabler-icons-react";

export default function Warning({ dict, largeScreen, setWarningOpened }) {

	const closeWarning = () => {
		setWarningOpened(false);
		localStorage.setItem("warningRead", true);
	};
	return (
		<Modal opened={true} onClose={closeWarning}
			size={largeScreen ? "50%" : "95%"}
			title={<Text weight={500}>{dict.warning}</Text>}>
			<Text mb="lg">{dict.warning1}</Text>
			<Text mb="lg">{dict.warning2}</Text>
			<Text weight={500}><AlertTriangle style={{ verticalAlign: "bottom" }} />&nbsp; {dict.warning3}</Text>

			<Group position="center">
				<Button mt="xl" onClick={closeWarning}>{dict.understood}</Button>
			</Group>
		</Modal>
	);
}