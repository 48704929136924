import React from "react";
import { FileButton, Button, Group, Loader } from "@mantine/core";
import { FileUpload, X } from "tabler-icons-react";
import TemplateFile from "./templateFile";
import { showNotification } from "@mantine/notifications";

export default function TemplateFiles({ dict, newFiles, setNewFiles, oldFiles, deleteOldFile }) {
	const addFiles = addedFiles => {
		if (addedFiles.some(file => file.size > 512000000))
			return showNotification({ message: dict.maxFileSize, color: "red", icon: <X /> });
		if (oldFiles.length + newFiles.length + addedFiles.length > 20)
			return showNotification({ message: dict.maxFiles, color: "red", icon: <X /> });

		setNewFiles([...newFiles, ...addedFiles]);
	};
	const removeFile = index => {
		setNewFiles(newFiles.filter((_, i) => i !== index));
	};

	if (!oldFiles)
		return <Loader />;

	return (
		<Group justify="center">
			{oldFiles.map((file, index) => (
				<TemplateFile key={index} file={file} removeFile={() => deleteOldFile(file.id)} />
			))}
			{newFiles.map((file, index) => (
				<TemplateFile key={index} file={file} removeFile={() => removeFile(index)} />
			))}
			{oldFiles.length + newFiles.length < 20 &&
				<FileButton onChange={addFiles} multiple
					accept=".c,.cpp,.csv,.docx,.html,.java,.json,.md,.pdf,.php,.pptx,.py,.rb,.tex,.txt,.css,.js,.tar,.ts,.xlsx,.xml,.zip">
					{props => <Button {...props} leftIcon={<FileUpload />} variant="outline">
						{dict.attachFile}
					</Button>}
				</FileButton>
			}
		</Group>
	);
}