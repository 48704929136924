import { Center, Grid, Loader, Paper, Text, useMantineTheme } from "@mantine/core";
import React, { useMemo } from "react";
import Chart from "react-google-charts";
import "../../css/charts.css";

export default function Charts({ dict, charts }) {
	const theme = useMantineTheme();

	const getChart = chart => {
		const chartColor = theme.colorScheme === "dark" ? theme.colors.gray[5] : theme.colors.gray[7];
		return <Chart chartType={chart.chartType || "LineChart"}
			data={chart.data}
			options={{
				chartArea: { width: "80%", top: 4, bottom: 50 },
				animation: { "startup": true, duration: 500, easing: "out" },
				legend: { position: "bottom", textStyle: { fontSize: 10, color: chartColor } },
				hAxis: { textStyle: { fontSize: 12, color: chartColor } },
				titleTextStyle: { fontSize: 13, color: chartColor },
				backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : "#FFF",
				isStacked: chart.stacked,
			}}
		/>;
	};

	const getChartContent = chart => {
		if (!chart.data) {
			return <Center><Loader /></Center>;
		}
		if (chart.data?.length < 2) {
			return <Text align="center" mt={50} size="sm">{dict.notEnoughData}</Text>;
		}
		return getChart(chart);
	};
	const renderedCharts = useMemo(() => charts.map(chart => (
		<Grid.Col key={chart.index} sm={12 / Math.min(charts.length, 2)} md={12 / Math.min(charts.length, 3)}>
			<Paper p="md" shadow="md" sx={{ minHeight: 264 }}>
				<Text mb="xs" align="center" size="sm" weight={500}>{dict[`chart${chart.type}`]}</Text>
				{getChartContent(chart)}
			</Paper>
		</Grid.Col>
	)), [charts]);

	return <Grid m="xs">{renderedCharts}</Grid>;
}