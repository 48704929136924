import React, { useState } from "react";

export default function TemplateIcon({ category }) {
	const [iconExists, setIconExists] = useState(true);

	const handleErrorLoading = () => {
		setIconExists(false);
	};
	const url = `${process.env.PUBLIC_URL}/templateIcons/${category?.split("/")[0].trim() || "Misc"}.png`;

	return (
		iconExists && <img src={url} width={16} onError={handleErrorLoading} /> 
	);
}