import { Indicator, Stack, Text, Tooltip } from "@mantine/core";
import React from "react";
import { FileText } from "tabler-icons-react";

export default function TemplateFile({ file, removeFile }) {

	return (
		<Tooltip label={file.name} multiline openDelay={200} style={{ maxWidth: 300 }} >
			<Stack style={{ width: 76 }} spacing={0}>
				<Indicator color="gray" size={20} label="X" offset={8} onClick={removeFile} style={{ cursor: "pointer" }}>
					<FileText size={36} strokeWidth={1} style={{ marginLeft: 18 }} />
				</Indicator>
				<Text truncate="end" size="xs">{file.name}</Text>
			</Stack>
		</Tooltip>
	);
}