import { Stack, Text, Tooltip } from "@mantine/core";
import React from "react";
import { FileText } from "tabler-icons-react";
import { downloadFile } from "utils/urlHelpers";

export default function ConversationMessageFile({ file, downloadable }) {
	const download = () => {
		if (downloadable)
			downloadFile(`chatFile/uid/${file.uid}`, file.name);
	};

	return (
		<Tooltip label={file.name} multiline openDelay={200} style={{ maxWidth: 300 }} >
			<Stack style={{ width: 64, cursor: downloadable ? "pointer" : undefined }}
				spacing={0} align="center" onClick={download}>
				<FileText size={36} strokeWidth={1} />
				<Text truncate="end" size="xs" style={{ width: "100%" }}>{file.name}</Text>
			</Stack>
		</Tooltip>
	);
}