import { objectToFormData } from "utils/helpers";
import { fetchUrl } from "utils/urlHelpers";
import useTemplateVisibility from "./useTemplateVisibility";

export default function useTemplateActions({ setSaving, setSavedTemplate, setDeletedTemplate, setTemplate, template, dict }) {
	const { visibilities } = useTemplateVisibility();

	const saveTemplate = (values, files, deletedFiles, userRights) => {
		if (values.visibility == visibilities.private) userRights = [];
		let data = { 
			...values, temperature: values.temperature / 10.0, files, deletedFiles,
			monitored: userRights.some(x => x.rights.includes("2")),
		};
		if (data.category === dict.miscellaneous) delete data.category;

		fetchUrl(`template/${values.id || ""}`, values.id ? "PUT" : "POST", objectToFormData(data), setSaving, data => {
			//transform properties "rights" from array of strings to bitwise => save
			const rights = userRights.filter(x => x.rights?.length > 0 && x.userId > 0)
				.map(x => ({ userId: +x.userId, rights: x.rights.reduce((a, b) => a | b, 0) }));

			fetchUrl(`template/${data.id}/userRights`, "POST", JSON.stringify(rights), setSaving, () => {
				setSavedTemplate(data);
				setTemplate(false);
			});
		});
	};

	const deleteTemplate = () => {
		fetchUrl(`template/${template.id}`, "DELETE", null, null, () => {
			setDeletedTemplate({ id: template.id });
			setTemplate(false);
		});
	};

	const removeTemplate = () => {
		fetchUrl(`templateCustomer/${template.id}`, "DELETE", null, null, () => {
			setDeletedTemplate({ id: template.id });
			setTemplate(false);
		});
	};

	return { saveTemplate, deleteTemplate, removeTemplate};
}