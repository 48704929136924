import React, { useState } from "react";
import { showNotification } from "@mantine/notifications";
import { Check } from "tabler-icons-react";
import ConversationEdit from "./conversationEdit";
import ConversationMessage from "./conversationMessage";
import "../../css/answer.css";

export default function ConversationMessages({ dict, conversation, editQuestion, largeScreen }) {
	const [editingConversation, setEditingConversation] = useState(null);

	const copyToClipboard = text => {
		navigator.clipboard.writeText(text);
		showNotification({
			icon: <Check size={18} />, color: "teal", message: dict.copiedToClipboard
		});
	};

	const updateQuestion = e => {
		e.preventDefault();
		editQuestion(editingConversation.id, editingConversation.question);
		setEditingConversation(null);
	};

	return (
		<>
			{conversation.map((message, index) => (
				<ConversationMessage key={index} dict={dict} message={message}
					copyToClipboard={copyToClipboard} setEditingConversation={setEditingConversation} />
			))}
			<ConversationEdit dict={dict} editingConversation={editingConversation} setEditingConversation={setEditingConversation}
				updateQuestion={updateQuestion} largeScreen={largeScreen} />
		</>
	);
}