import { Button, Group, Modal, Textarea } from "@mantine/core";
import React from "react";
import { Send, X } from "tabler-icons-react";

export default function ConversationEdit({ dict, editingConversation, setEditingConversation, updateQuestion, largeScreen }) {

	return (
		<Modal opened={!!editingConversation} onClose={() => setEditingConversation(null)} title={dict.editQuestion}
			fullScreen={!largeScreen} size={largeScreen ? 800 : undefined }>
			<Textarea autosize
				value={editingConversation?.question}
				onChange={e => setEditingConversation({ ...editingConversation, question: e.target.value })}
				onKeyDown={e => e.key === "Enter" && !e.altKey && !e.shiftKey && updateQuestion(e)}
			/>
			<Group position="right" mt={8}>
				<Button variant="outline" color="gray" onClick={() => setEditingConversation(null)} leftIcon={<X size={18} />}>
					{dict.cancel}
				</Button>
				<Button variant="outline" color="blue" onClick={updateQuestion} leftIcon={<Send size={18} />}>
					{dict.send}
				</Button>
			</Group>
		</Modal>
	);
}