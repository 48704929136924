import React, { useEffect, useState } from "react";
import { Group, TextInput, PasswordInput, Button, Anchor, Stack } from "@mantine/core";
import { Check } from "tabler-icons-react";
import { fetchUrl } from "utils/urlHelpers";
import { emailToName, objectToFormData } from "utils/helpers";

export default function Login({ setToken }) {
	const [company, setCompany] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [logging, setLogging] = useState("");

	useEffect(() => {
		const details = JSON.parse(localStorage.getItem("userEmail") || "{}");
		if (new Date(details.expire) > new Date()) {
			setCompany(details.company || "");
			setEmail(details.email || "");
		}
		const company = new URLSearchParams(window.location.search).get("company");
		if (company) setCompany(company);
	}, []);

	const login = e => {
		e.preventDefault();
		fetchUrl(`user/bigchange/login/${company}`, "POST", objectToFormData({ email: email, password: password }), setLogging, loginOk, null, null, "Wrong credentials");
	};
	const loginOk = data => {
		const token = data.token;
		const savedDetails = { company: data.customerName, email, admin: data.admin, companyDescription: data.companyDescription, expire: new Date((new Date()).getTime() + 10080 * 60000) }; // 1 week
		const sessionDetails = { token: token, expire: new Date((new Date()).getTime() + 4 * 60 * 60000) }; // 4 hours
		localStorage.setItem("userEmail", JSON.stringify(savedDetails));
		sessionStorage.setItem("userLogged", JSON.stringify(sessionDetails));
		setToken(token);
		trackWithPendo(email, data.customerId, data.customerName);
	};

	const trackWithPendo = (email, customerId, customerName) => {
		// eslint-disable-next-line no-undef
		pendo.initialize({
			visitor: {
				id: email.toLowerCase(),
				full_name: emailToName(email),
			}, account: {
				id: `PROD-GPT-${customerId}`,
				name: customerName
			}
		});
	};

	return (
		<Group position="center">
			<Stack sx={{ maxWidth: 500, padding: 10 }} >
				<form onSubmit={login}>
					<TextInput value={company} onChange={e => setCompany(e.currentTarget.value)} label="Company" required />
					<TextInput value={email} onChange={e => setEmail(e.currentTarget.value)} label="Email" required />
					<PasswordInput value={password} label="Password" required onChange={e => setPassword(e.currentTarget.value)} />
					<Group position="apart" mt="sm">
						<Anchor href="https://clients.bigchange.com/LoginRetrieve.aspx">Retrieve your BigChange password</Anchor>
						<Button leftIcon={<Check size={20} />} type="submit" loading={logging}>Login</Button>
					</Group>
				</form>
			</Stack>
		</Group>
	);
}