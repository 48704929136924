function buildFormData(formData, obj, parentKey) {
	if (obj && Array.isArray(obj))
		obj.forEach(value => {
			formData.append(parentKey, value);
		});
	else if (obj && typeof obj === "object" && !(obj instanceof Date) && !(obj instanceof File)) {
		Object.keys(obj).forEach(key => {
			buildFormData(formData, obj[key], parentKey ? `${parentKey}[${key}]` : key);
		});
	} else if (obj !== undefined && obj !== null)
		formData.append(parentKey, obj);
}
export function objectToFormData(obj) {
	let formData = new FormData();
	buildFormData(formData, obj);
	return formData;
}

export function linkify(text) {
	const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
	return text.replace(urlRegex, function (url) {
		return `<a href="${url}" target="blank">${url}</a>`;
	});
}

export function emailToName(email) {
	return email.split("@")[0].replace(".", " ").toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
}

export function isValidJson(str) {
	try {
		JSON.parse(str);
	} catch (_) {
		return false;
	}
	return true;
}

export function isValidUrl(url) {
	try {
		new URL(url);
	} catch (_) {
		return false;
	}
	return true;
}