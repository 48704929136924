import React from "react";
import { Prism } from "@mantine/prism";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { downloadFile } from "utils/urlHelpers";

export default function useConversation() {
	const parseMessage = text => {
		const parts = text.split(/(```[\s\S]*?```)/);
		return parts.map(part => {
			if (part.startsWith("```") && part.endsWith("```")) {
				const scriptContent = part.slice("```".length, -"```".length);
				return { type: "script", content: scriptContent.trim() };
			}
			return { type: "text", content: part.trim() };
		}).filter(({ content }) => content !== "");
	};

	const openFile = (e, href) => {
		e.preventDefault();
		downloadFile(`chatFile/uid/${href}`, e.target.innerText);
	};

	const LinkRenderer = ({ href, children }) => {
		if (href.startsWith("http"))
			return <a href={href} target="_blank" rel="noReferrer">{children}</a>;
		else
			return <a href={href} onClick={e => openFile(e, href)}>{children}</a>;
	};
	const formatMessage = text => {
		if (!text) return "🤨";

		return parseMessage(text).map(({ type, content }, index) => {
			if (type === "text")
				return <ReactMarkdown key={index} components={{ a: LinkRenderer }} remarkPlugins={[remarkGfm]}>
					{content.replace(/\n/g, "  \n").replace(/\t/g, "    ")}
				</ReactMarkdown>;
			if (type === "script")
				return <Prism key={index} language={content.split("\n")[0]}
					my="xl" sx={{ maxWidth: 1000 }}>
					{content.substring(content.indexOf("\n") + 1)}
				</Prism>;
		});
	};
	return formatMessage;
}