import React, { useEffect, useRef } from "react";
import { ActionIcon, SegmentedControl, Textarea } from "@mantine/core";
import { Send } from "tabler-icons-react";
import { getUrl } from "utils/urlHelpers";
import { useMediaQuery } from "@mantine/hooks";
import useChat from "hooks/useChat";
import Conversation from "../conversation/conversation";
import ChatFunctions from "./chatFunctions";
import ChatFiles from "./chatFiles";

export default function Chat({ dict, loadedConversationUid, setNewConversation, deletedConversationUid }) {
	const {
		conversation, setConversation, setTemplateId, question, setQuestion, model, setModel, usingFunctions, setUsingFunctions,
		conversationUid, setConversationUid, loading, askQuestion, regenerate, editQuestion, splitLoadedQAs, files, setFiles
	} = useChat({ setNewConversation });
	const txtQuestion = useRef(null);

	useEffect(() => {
		setConversationUid(loadedConversationUid);
		setFiles([]);
		if (!loadedConversationUid) {
			setConversation([]);
			setTemplateId(null);
		} else if (loadedConversationUid !== conversationUid) {
			getUrl(`chatHistory/${loadedConversationUid}`, response => {
				setConversation(splitLoadedQAs(response));
				setTemplateId(response[0].templateId);
			});
		}
	}, [loadedConversationUid]);

	useEffect(() => {
		setQuestion("");
		txtQuestion.current.focus();
	}, [conversation]);

	useEffect(() => {
		if (deletedConversationUid === conversationUid) {
			setConversationUid(null);
			setConversation([]);
			setTemplateId(null);
		}
	}, [deletedConversationUid]);

	const largeScreen = useMediaQuery("(min-width: 800px)");

	const models = [{ label: "ChatGPT 3.5", value: "1" }, { label: "ChatGPT 4", value: "3" }];

	return (
		<div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<Conversation dict={dict} conversation={conversation} txtQuestion={txtQuestion} regenerate={regenerate}
				askQuestion={askQuestion} editQuestion={editQuestion} largeScreen={largeScreen} usingFunctions={usingFunctions} />
			<div style={{ display: "flex", marginTop: 16, flexDirection: largeScreen ? "row" : "column" }}>
				<ChatFiles files={files} setFiles={setFiles} compact />
				<Textarea ref={txtQuestion} placeholder={dict.typeHere} ml={largeScreen ? 8 : 0}
					value={question} onChange={e => setQuestion(e.target.value)}
					rows={1} autosize disabled={loading}
					onKeyDown={e => e.key === "Enter" && !e.altKey && !e.shiftKey && askQuestion(e)}
					rightSection={<ActionIcon onClick={askQuestion} mr="lg"><Send /></ActionIcon>}
					sx={{ flexGrow: 1 }}
				/>
				<div style={{ flexShrink: 0, marginLeft: largeScreen ? 16 : 0, marginTop: largeScreen ? 4 : 8 }}>
					<SegmentedControl value={model} onChange={setModel} sx={{ maxHeight: 40 }} data={models} />
				</div>
				<ChatFunctions usingFunctions={usingFunctions} setUsingFunctions={setUsingFunctions} largeScreen={largeScreen} />
			</div>
		</div>
	);
}