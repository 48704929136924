import React, { useEffect, useState } from "react";
import { Copy, Edit } from "tabler-icons-react";
import { ActionIcon, Group, Paper, Stack, Text, Tooltip } from "@mantine/core";
import { ReactComponent as LoadingIcon } from "../../svg/loading.svg";
import { getUrl } from "utils/urlHelpers";
import ConversationMessageFile from "./conversationMessageFile";
import useConversation from "hooks/useConversation";

export default function ConversationMessage({ dict, message, copyToClipboard, setEditingConversation }) {
	const formatMessage = useConversation();
	const [files, setFiles] = useState([]);

	useEffect(() => {
		setFiles([]);
		if (message.from == "user" && message.hasUserFiles || message.from == "bot" && message.hasAssistantFiles)
			getUrl(`chatHistory/${message.id}/files?source=${message.from == "user" ? 1 : 2}`, setFiles);
		if (message.from == "user" && message.files)
			setFiles(message.files);
	}, [message.hasAssistantFiles]);

	return (
		<Paper p="md" radius={12} sx={{ backgroundColor: message.from === "bot" ? "black" : undefined }}>
			<div style={{ display: "flex" }}>
				<img src={message.from === "user" ? "/user.svg" : "/logo192.png"} width={32} height={32} />
				<Stack sx={{ flexGrow: 1 }}>
					<Text ml="sm" className="answer">
						{message.loading && <LoadingIcon style={{ fill: "white" }} />}
						{!message.loading && message.from == "user" &&
							message.question.split("\n").map((x, i) => <span key={i}>{x}<br /></span>)}
						{!message.loading && message.from == "bot" &&
							formatMessage(message.answer)
						}
					</Text>
					<Group>
						{files.map((file, index) => (
							<ConversationMessageFile key={index} file={file} downloadable={message.from == "bot"} />
						))}
					</Group>
				</Stack>
				{message.from == "user" &&
					<Tooltip label={dict.edit} withArrow openDelay={200}>
						<ActionIcon variant="outline" color="gray" onClick={() => setEditingConversation(message)}>
							<Edit size={18} />
						</ActionIcon>
					</Tooltip>
				}
				{message.from == "bot" &&
					<Tooltip label={dict.copyToClipboard} withArrow openDelay={200}>
						<ActionIcon variant="outline" color="gray" onClick={() => copyToClipboard(message.answer)}>
							<Copy size={18} />
						</ActionIcon>
					</Tooltip>
				}
			</div>
		</Paper>
	);
}