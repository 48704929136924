import React from "react";
import { Group, Menu, Stack, Text, Tooltip } from "@mantine/core";
import { Edit, Eye, MessageCircle2, Pin, PinnedOff, Trash } from "tabler-icons-react";

export default function HistoryConversation({ dict, conversations, updateConversation, deleteConversation, setLoadedConversationUid, setRenameConversation }) {
	return (
		<Stack spacing={8} my="md" sx={{ width: 200, flexGrow: 1, overflowY: "auto" }}>
			<Text weight={500} mb="xs">{dict.history}</Text>
			{Object.entries(conversations).map(([group, conversations], index) => (
				conversations.length > 0 && (
					<span key={index}>
						<Text weight={500} size="sm" mb="xs">{group}</Text>
						{conversations.map(conversation => (
							<Group key={conversation.uid} sx={{ cursor: "pointer" }} spacing="xs">
								{conversation.pinned
									? <Pin size={16} />
									: <MessageCircle2 size={16} />
								}
								<Menu shadow="md" width={200}>
									<Menu.Target>
										<Tooltip label={conversation.title || dict.untitled}>
											<Text size="sm" truncate sx={{ maxWidth: 150 }}>{conversation.title || <i>{dict.untitled}</i>}</Text>
										</Tooltip>
									</Menu.Target>
									<Menu.Dropdown>
										<Menu.Label>{conversation.title}</Menu.Label>
										<Menu.Item icon={<Eye size={14} />} onClick={() => setLoadedConversationUid(conversation.uid)}>
											{dict.openConversation}
										</Menu.Item>
										<Menu.Item icon={<Edit size={14} />} onClick={() => setRenameConversation(conversation)}>
											{dict.renameConversation}
										</Menu.Item>
										<Menu.Item icon={conversation.pinned ? <PinnedOff size={14} /> : <Pin size={14} />} onClick={() => updateConversation(conversation, true)}>
											{conversation.pinned ? dict.unpinConversation : dict.pinConversation}
										</Menu.Item>
										<Menu.Item icon={<Trash size={14} />} onClick={() => deleteConversation(conversation.uid)} color="red">
											{dict.deleteConversation}
										</Menu.Item>
									</Menu.Dropdown>
								</Menu>
							</Group>
						))}
					</span>
				)
			))}
		</Stack>
	);
}
