import { ActionIcon, Group, Modal, Tabs, Text } from "@mantine/core";
import React from "react";
import AdminTemplates from "./adminTemplate/adminTemplates";
import Dashboard from "./adminDashboard";
import { useNavigate, useParams } from "react-router-dom";
import { useDictionary } from "i18n/dictionary";
import { useMediaQuery } from "@mantine/hooks";
import { ArrowLeft } from "tabler-icons-react";

export default function Admin() {
	const dict = useDictionary();
	const largeScreen = useMediaQuery("(min-width: 800px)");
	const navigate = useNavigate();
	const { tabValue } = useParams();

	const onClose = () => {
		const path = window.location.pathname.split("/");
		if (path.length > 2) {
			path.pop();
			navigate(path.join("/"));
		} else 
			navigate("/");
	};

	return (
		<Modal opened={true} onClose={onClose} fullScreen withCloseButton={false} styles={{ content: { overflowX: "hidden" } }}
			title={<Group>
				<ActionIcon compact onClick={onClose} variant="filled" color="blue" size={24}>
					<ArrowLeft /></ActionIcon><Text>{dict.admin}
				</Text>
			</Group>}
		>
			<Tabs value={tabValue} onTabChange={value => navigate(`/admin/${value}`)} orientation={largeScreen ? "vertical" : "horizontal"}>
				<Tabs.List mr="md">
					<Tabs.Tab value="templates">{dict.templates}</Tabs.Tab>
					<Tabs.Tab value="dashboard">Dashboard</Tabs.Tab>
					<Tabs.Tab value="users">Users</Tabs.Tab>
				</Tabs.List>
				{tabValue == "templates" && <Tabs.Panel value="templates"><AdminTemplates dict={dict} /></Tabs.Panel>}
				{tabValue == "dashboard" && <Tabs.Panel value="dashboard"><Dashboard dict={dict} /></Tabs.Panel>}
				{tabValue == "users" && <Tabs.Panel value="users">Work in progress</Tabs.Panel>}
			</Tabs>
		</Modal>
	);
}