import { Button, FileButton, Group, Loader, Text, Textarea } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import useScrape from "hooks/useScrape";
import React, { useEffect, useState } from "react";
import { Upload, X } from "tabler-icons-react";
import { isValidJson, isValidUrl, objectToFormData } from "utils/helpers";
import { fetchUrl } from "utils/urlHelpers";

export default function TemplateInput({ dict, template, applyTemplate, label, values, prompt, updatePrompt, isFirst, isLast }) {
	const param = isValidJson(`{${label}}`)
		? JSON.parse(`{${label}}`)
		: { label, type: "text", hidden: label == "Enter your name" || label == "Company name" };

	const [value, setValue] = useState(values[param.label]);
	const [file, setFile] = useState(null);
	const [uploading, setUploading] = useState(null);
	
	const sectionsLoaded = sections => {
		if (sections.length == 0)
			showNotification({ icon: <X size={18} />, color: "red", message: dict.pdfEmpty });
		else {
			const newValue = sections.join("\n");
			setValue(newValue);
			let newPrompt = updatePrompt(template, label, newValue);
			applyTemplate(newPrompt, template.id);
		}
	};

	const { extractUrlMainContent } = useScrape(sectionsLoaded);
	const [scraping, setScraping] = useState(null);

	useEffect(() => {
		if (param.url && isValidUrl(value))
			scrapeUrl(value);
		updatePrompt(template, label, value);
	}, [value]);

	useEffect(() => {
		if (file)
			fetchUrl("template/pdf", "POST", objectToFormData({ file: file }), setUploading, sectionsLoaded);
	}, [file]);

	const scrapeUrl = url => {
		extractUrlMainContent(url, setScraping);
	};

	const inputLabel = <Group>
		<Text>{param.label}</Text>
		{param.upload == "pdf" &&
			<FileButton onChange={setFile} accept="application/pdf">
				{props => <Button {...props} compact variant="light" mb={4} loading={uploading} leftIcon={<Upload strokeWidth={1.5} size={18} />}>
					{dict.uploadPDF}
				</Button>}
			</FileButton>}
	</Group>;

	return (
		<Textarea autosize autoFocus={isFirst} mb="lg" label={inputLabel} disabled={scraping} 
			icon={scraping && <Loader />}
			value={value} onChange={e => setValue(e.target.value)}
			minRows={param.label.toLowerCase().indexOf("name") != -1 ? 1 : 3}
			sx={param.hidden ? { display: "none" } : undefined}
			onKeyDown={e => e.key === "Enter" && !e.altKey && !e.shiftKey && isLast && applyTemplate(prompt, template.id)} />
	);
}