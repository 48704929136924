import React, { useState } from "react";
import { ActionIcon, Button, Grid, Indicator, Menu, Modal } from "@mantine/core";
import Template from "pages/templateEdit/template";
import { DotsVertical, Edit, List } from "tabler-icons-react";
import TemplateLogs from "./templateLogs";
import useTemplateVisibility from "hooks/useTemplateVisibility";
import { useNavigate } from "react-router-dom";

export default function TemplatesButton({ dict, template, dateIsWithinLast10Days, templateRights, setSavedTemplate, setDeletedTemplate }) {
	const [editTemplateOpened, setEditTemplateOpened] = useState(false);
	const [logOpened, setLogOpened] = useState(false);
	const { visibilities } = useTemplateVisibility();
	const navigate = useNavigate();

	// userRight is a bitwise number, 1 = Admin, 2 = Logs
	const userRights = templateRights?.find(x => x.templateId == template.id)?.rights;

	return (
		<Grid.Col sm={6} lg={4} xl={3}>
			<div style={{ display: "flex", gap: 8, alignItems: "stretch" }}>
				<Indicator inline disabled={!dateIsWithinLast10Days(template.dateInserted)}
					label="New" size={16} sx={{ flexGrow: 1 }}>
					<Button variant="light" onClick={() => navigate(`/templates/${template.id}`)} sx={{ width: "100%" }}>
						{template.name}
					</Button>
				</Indicator>
				{(userRights || template.visibility == visibilities.private) &&
					<Menu shadow="md" width={200}>
						<Menu.Target>
							<ActionIcon variant="light" color="blue"><DotsVertical size={18} /></ActionIcon>
						</Menu.Target>
						<Menu.Dropdown>
							{(!!(userRights & 1) || template.visibility == visibilities.private) &&
								<Menu.Item icon={<Edit size={14} />} onClick={() => setEditTemplateOpened(true)}>{dict.edit}</Menu.Item>
							}
							{!!(userRights & 2) &&
								<Menu.Item icon={<List size={14} />} onClick={() => setLogOpened(true)}>{dict.viewLogs}</Menu.Item>
							}
						</Menu.Dropdown>
					</Menu>
				}
			</div>

			<Modal opened={editTemplateOpened} onClose={() => setEditTemplateOpened(false)} size="90%" withCloseButton={false}>
				<Template dict={dict} template={template} setTemplate={setEditTemplateOpened} setSavedTemplate={setSavedTemplate} setDeletedTemplate={setDeletedTemplate} />
			</Modal>
			<Modal opened={logOpened} onClose={() => setLogOpened(false)} size="90%">
				<TemplateLogs dict={dict} templateId={template.id} />
			</Modal>
		</Grid.Col>
	);
}