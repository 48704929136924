import React from "react";
import { HoverCard, Switch, Text } from "@mantine/core";

export default function ChatFunctions({ usingFunctions, setUsingFunctions, largeScreen }) {
	const user = JSON.parse(localStorage.getItem("userEmail"));
	const isBeta = ["johann.levy@bigchange.com", "lee.wilkinson@bigchange.com", "ann.dewar@bigchange.com", "danielle.kayman@bigchange.com", "steven.collins@bigchange.com", "jack.tyson@bigchange.com", "rowan.hanson@bigchange.com", "andrew.knight@bigchange.com", "steve.logan@bigchange.com", "john.hird@bigchange.com", "andrew.davenport@bigchange.com", "ian.burgess@bigchange.com", "matt.ashworth@bigchange.com", "alekhya.ankarapu@bigchange.com", "lee.trenam@bigchange.com", "beryl.cairns-hockey@bigchange.com", "jonathan.isaacs@bigchange.com", "graeme.rutherford-doak@bigchange.com", "david.pursehouse@bigchange.com", "ryan.lichtenberg@bigchange.com", "oliver.pearson@bigchange.com", "martin.port@bigchange.com"]
		.includes(user.email.toLowerCase());

	return (
		<HoverCard width={280} shadow="md" disabled={isBeta}>
			<HoverCard.Target >
				<div style={{
					flexShrink: 0, marginLeft: largeScreen ? 16 : 0, marginTop: largeScreen ? 16 : 8,
					cursor: isBeta ? "default" : "not-allowed"
				}}>
					<Switch label="BigChange data" size="sm" checked={usingFunctions} disabled={!isBeta}
						onChange={e => setUsingFunctions(e.target.checked)} />
				</div>
			</HoverCard.Target>
			<HoverCard.Dropdown>
				<Text size="sm">This feature is in early beta and only available to a few users. Please email <a href="mailto:johann.levy@bigchange.com">johann.levy@bigchange.com</a> if you want to try it.</Text>
			</HoverCard.Dropdown>
		</HoverCard>
	);
}