import React, { useEffect, useState } from "react";
import { Button, Checkbox, Group, NumberInput, SegmentedControl, SimpleGrid, Slider, Stack, Text, TextInput, Textarea, Tooltip } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ArrowLeft, DeviceFloppy, InfoCircle } from "tabler-icons-react";
import { getUrl } from "utils/urlHelpers";
import Confirm from "components/Confirm";
import useTemplateActions from "hooks/useTemplateActions";
import TemplateFiles from "./templateFiles";
import TemplateRights from "./templateRights";
import useTemplateVisibility from "hooks/useTemplateVisibility";

export default function Template({ dict, template, setTemplate, setSavedTemplate, setDeletedTemplate }) {
	const [saving, setSaving] = useState(null);
	const [deleteConfOpened, setDeleteConfOpened] = useState(null);
	const [removeConfOpened, setRemoveConfOpened] = useState(null);
	const [newFiles, setNewFiles] = useState([]);
	const [oldFiles, setOldFiles] = useState(null);
	const [deletedOldFiles, setDeletedOldFiles] = useState(null);
	const [userRights, setUserRights] = useState(null);

	const { visibilities } = useTemplateVisibility();
	const { saveTemplate, deleteTemplate, removeTemplate } = useTemplateActions({
		setSaving, setSavedTemplate, setDeletedTemplate, setTemplate, template, dict
	});

	const bigChangeAccount = JSON.parse(localStorage.getItem("userEmail")).company == "BigChange";
	const userIsAdmin = JSON.parse(localStorage.getItem("userEmail")).admin;
	const visibilityOptions = [{ value: visibilities.private, label: dict.private }];
	if (userIsAdmin) visibilityOptions.push({ value: visibilities.company, label: dict.company });
	if (userIsAdmin && bigChangeAccount) visibilityOptions.push({ value: visibilities.public, label: dict.public });

	const form = useForm({
		initialValues: {
			...template,
			category: template.category || dict.miscellaneous,
			minutesSaved: template.minutesSaved || "",
			autoDeleteDays: template.autoDeleteDays || "",
			context: template.context || "",
			visibility: template.visibility ? template.visibility + "" : (userIsAdmin ? visibilities.company : visibilities.private),
			temperature: template.temperature == null || template.temperature == undefined ? 5 : template.temperature * 10,
		}
	});

	useEffect(() => {
		if (template.id)
			getUrl(`template/${template.id}/users`, rights =>
				//transform properties "right" from bitwise to array of strings
				setUserRights(rights.map(x => ({ userId: x.userId + "", rights: ["1", "2"].filter(y => x.rights & y) })))
			);
		else
			setUserRights([]);
		if (template.assistantId)
			getUrl(`template/${template.id}/files`, setOldFiles);
		else
			setOldFiles([]);
	}, []);

	const deleteOldFile = id => {
		setDeletedOldFiles([...(deletedOldFiles || []), id]);
		setOldFiles(oldFiles.filter(file => file.id !== id));
	};

	return (
		<form onSubmit={form.onSubmit(values => saveTemplate(values, newFiles, deletedOldFiles, userRights))}>
			<Stack>
				<SimpleGrid cols={userIsAdmin ? 3 : 1}>
					<TextInput required label={dict.name} {...form.getInputProps("name")} maxLength={50} />
					{userIsAdmin && <>
						<TextInput required label={dict.category} {...form.getInputProps("category")} maxLength={50} />
						<Stack spacing={0}>
							<Text size="sm" weight={500}>{dict.visibility}</Text>
							<SegmentedControl label={dict.visibility} {...form.getInputProps("visibility")} data={visibilityOptions} />
						</Stack>
					</>}
				</SimpleGrid>
				<Textarea required {...form.getInputProps("intro")} maxLength={200} minRows={2} autosize
					label={<Text sx={{ display: "inline" }}>{dict.description} <Tooltip label={dict.descriptionHelp} multiline width={300}><span><InfoCircle size={16} strokeWidth={1.5} /></span></Tooltip></Text>} />
				<Textarea {...form.getInputProps("context")} maxLength={3500} minRows={2} maxRows={7} autosize
					label={<Text sx={{ display: "inline" }}>{dict.context} <Tooltip label={dict.contextHelp} multiline width={300}><span><InfoCircle size={16} strokeWidth={1.5} /></span></Tooltip></Text>} />
				<Textarea required {...form.getInputProps("prompt")} minRows={2} maxRows={7} autosize
					label={<Text sx={{ display: "inline" }}>{dict.prompt} <Tooltip label={dict.promptHelp} multiline width={300}><span><InfoCircle size={16} strokeWidth={1.5} /></span></Tooltip></Text>} />
				<SimpleGrid cols={4}>
					<Checkbox {...form.getInputProps("fileRequired", { type: "checkbox" })} label={dict.fileRequired} mt="xl" />
					<NumberInput label={dict.minutesSaved} {...form.getInputProps("minutesSaved")} maxLength={4} />
					<NumberInput label={dict.autoDeleteDays} {...form.getInputProps("autoDeleteDays")} maxLength={4} />
					<Stack spacing={0}>
						<Text size="sm" weight={500}>{dict.creativity}</Text>
						<Slider min={0} max={10} step={1} {...form.getInputProps("temperature")} label={null}
							marks={[
								{ value: 2, label: dict.notCreative },
								{ value: 5, label: "" },
								{ value: 8, label: dict.veryCreative },
							]}
						/>
					</Stack>
				</SimpleGrid>
				{userRights && form.values.visibility != visibilities.private && <TemplateRights dict={dict} userRights={userRights} setUserRights={setUserRights} />}
				<TemplateFiles dict={dict} newFiles={newFiles} setNewFiles={setNewFiles} oldFiles={oldFiles} deleteOldFile={deleteOldFile} />
			</Stack>
			<Group position="center" mt="xl">
				<Button variant="outline" leftIcon={<ArrowLeft />} onClick={() => setTemplate(false)}>{dict.cancel}</Button>
				{// Can delete if template is public and user is admin, or if template is company and user is admin, or if template is private
					template.id && setDeletedTemplate && (
						form.values.visibility == visibilities.public && bigChangeAccount && userIsAdmin
						|| form.values.visibility == visibilities.company && userIsAdmin
						|| form.values.visibility == visibilities.private
					)
					&& <Button variant="outline" color="red" onClick={() => setDeleteConfOpened(true)}>{dict.delete}</Button>}
				{// can remove if template is public and user is admin and company is bigchange
					template.id && setDeletedTemplate && form.values.visibility == visibilities.public && bigChangeAccount && userIsAdmin
					&& <Button variant="outline" color="red" onClick={() => setRemoveConfOpened(true)}>{dict.remove}</Button>}
				{// can't save if template is public and user is not admin at bigchange
					((bigChangeAccount && userIsAdmin) || form.values.visibility != visibilities.public)
					&& <Button type="submit" leftIcon={<DeviceFloppy />} loading={saving}>{dict.save}</Button>}
			</Group>

			<Confirm opened={deleteConfOpened} setOpened={setDeleteConfOpened} yesLabel={dict.delete} yesAction={deleteTemplate}>
				{bigChangeAccount && userIsAdmin && form.values.visibility == visibilities.public ? dict.delPublicTemplateConf : dict.delTemplateConf}
			</Confirm>
			<Confirm opened={removeConfOpened} setOpened={setRemoveConfOpened} yesLabel={dict.remove} yesAction={removeTemplate}>
				{dict.removeTemplateConf}
			</Confirm>
		</form>
	);
}
