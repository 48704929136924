import React, { useEffect, useState } from "react";
import { Button } from "@mantine/core";
import { Plus } from "tabler-icons-react";
import { fetchUrl, getUrl } from "utils/urlHelpers";
import HistoryUser from "./historyUser";
import { objectToFormData } from "utils/helpers";
import HistoryRename from "./historyRename";
import dayjs from "dayjs";
import HistoryConversation from "./historyConversation";

export default function History({ dict, setLoadedConversationUid, newConversation, setDeletedConversationUid, closeHistory, logout }) {
	const [conversations, setConversations] = useState(null);
	const [renameConversation, setRenameConversation] = useState(null);

	useEffect(() => {
		if (!conversations && dict.newConversation)
			loadConversations();
	}, [dict]);

	useEffect(() => {
		if (newConversation && conversations) {
			let newConversations = { ...conversations };
			newConversations[dict.today].unshift({ uid: newConversation.conversationUid, title: newConversation.title });
			setConversations(newConversations);
			setLoadedConversationUid(newConversation.conversationUid);
		}
	}, [newConversation]);

	const loadConversations = () => {
		getUrl("conversation", data => setConversations(groupConversations(data)));
	};

	const groupConversations = conversations => {
		const groupedConversations = { [dict.pinned]: [], [dict.today]: [], [dict.previous7Days]: [], [dict.previous30Days]: [], [dict.older]: [] };
		conversations.forEach(conversation => {
			const diffDays = dayjs().diff(dayjs(conversation.date), "day");
			if (conversation.pinned) groupedConversations[dict.pinned].push(conversation);
			else if (diffDays < 1) groupedConversations[dict.today].push(conversation);
			else if (diffDays <= 7) groupedConversations[dict.previous7Days].push(conversation);
			else if (diffDays <= 30) groupedConversations[dict.previous30Days].push(conversation);
			else groupedConversations[dict.older].push(conversation);
		});
		return groupedConversations;
	};

	const deleteConversation = conversationUid => {
		fetchUrl(`conversation/${conversationUid}`, "DELETE", null, null, loadConversations);
		setDeletedConversationUid(conversationUid);
	};

	const updateConversation = (conversation, togglePin, newTitle) => {
		setRenameConversation(null);
		const data = {
			newTitle: newTitle || conversation.title,
			pinned: togglePin ? !conversation.pinned : conversation.pinned
		};
		fetchUrl(`conversation/${conversation.uid}`, "PUT", objectToFormData(data), null, loadConversations);
	};

	return (
		<div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<Button variant="outline" color="gray" mr="xl" onClick={() => { setLoadedConversationUid(null); closeHistory(); }} leftIcon={<Plus size={18} />}>
				{dict.newConversation}
			</Button>
			{conversations?.[dict.today]
				? <HistoryConversation dict={dict} conversations={conversations} updateConversation={updateConversation}
					deleteConversation={deleteConversation} setLoadedConversationUid={setLoadedConversationUid}
					setRenameConversation={setRenameConversation} />
				: <span style={{ flexGrow: 1 }}></span>				
			}
			<HistoryUser dict={dict} setLoadedConversationUid={setLoadedConversationUid} closeHistory={closeHistory} logout={logout} />
			{renameConversation &&
				<HistoryRename dict={dict} conversation={renameConversation} updateConversation={updateConversation} setVisible={setRenameConversation} />
			}
		</div>
	);
}
