import { Modal, Grid, Tabs, Group, TextInput, Indicator, Button } from "@mantine/core";
import React, { useState } from "react";
import TemplateIcon from "./templateIcon";
import useTemplates from "hooks/useTemplates";
import TemplatesButton from "./templatesButton";
import { Plus } from "tabler-icons-react";
import Template from "pages/templateEdit/template";
import useTemplateVisibility from "hooks/useTemplateVisibility";
import { useNavigate } from "react-router-dom";

export default function Templates({ dict, largeScreen }) {
	const [activeTab, setActiveTab] = useState("Misc");
	const { templates, templateRights, setSavedTemplate, setDeletedTemplate } = useTemplates();
	const { visibilities } = useTemplateVisibility();
	const [filter, setFilter] = useState("");
	const [newTemplate, setNewTemplate] = useState(false);
	const navigate = useNavigate();

	const categories = [...new Set(templates?.map(x => x.category))];
	categories.splice(1, 0, dict.private);

	const modalTitle = <Group position="apart" sx={{ width: "100%" }}>
		<span>{dict.template}</span>
		<TextInput placeholder={dict.search} size="xs" value={filter} onChange={e => setFilter(e.currentTarget.value)} />
	</Group>;

	const dateIsWithinLast10Days = date => {
		const today = new Date();
		const last10Days = new Date(today.setDate(today.getDate() - 10));
		return new Date(date) > last10Days;
	};

	const categoryHasRecentTemplates = category => {
		return templates?.filter(t => t.category == category)
			.some(t => t.visibility != visibilities.private && dateIsWithinLast10Days(t.dateInserted));
	};

	if (newTemplate)
		return <Modal opened={true} onClose={() => setNewTemplate(false)} size="90%" withCloseButton={false}>
			<Template dict={dict} template={newTemplate} setTemplate={setNewTemplate} setSavedTemplate={setSavedTemplate} setDeletedTemplate={setDeletedTemplate} />
		</Modal>;

	return (
		<Modal opened={true} size="70%" title={modalTitle} fullScreen={!largeScreen}
			onClose={() => navigate("/")} styles={{ title: { flex: 0.66 } }}>

			{filter != "" &&
				<Grid cols={2} align="center" my="xl">
					{templates?.filter(t => t.name.toLowerCase().indexOf(filter) != -1 || t.intro.toLowerCase().indexOf(filter) != -1 || t.category?.toLowerCase().indexOf(filter) > -1)
						.map((t, index) =>
							<TemplatesButton key={index} dict={dict} template={t} dateIsWithinLast10Days={dateIsWithinLast10Days} templateRights={templateRights} setSavedTemplate={setSavedTemplate} setDeletedTemplate={setDeletedTemplate} />
						)}
				</Grid>}

			{filter == "" &&
				<Tabs value={activeTab} onTabChange={setActiveTab}>
					<Tabs.List>
						{categories.map((x, i) => (
							<Tabs.Tab key={i} value={x || "Misc"} icon={<TemplateIcon category={x} />}>
								<Indicator inline disabled={!categoryHasRecentTemplates(x)} label="New" size={16} offset={-4}>
									{x || dict.miscellaneous}
								</Indicator>
							</Tabs.Tab>
						))}
					</Tabs.List>
					{categories.map((x, i) => (
						<Tabs.Panel key={i} value={x || "Misc"}>
							<Grid cols={2} align="center" my="xl">
								{templates?.filter(t => i != 1 && t.category == x && t.visibility != visibilities.private || i == 1 && t.visibility == visibilities.private)
									.map((t, index) =>
										<TemplatesButton key={index} dict={dict} template={t} dateIsWithinLast10Days={dateIsWithinLast10Days} templateRights={templateRights} setSavedTemplate={setSavedTemplate} setDeletedTemplate={setDeletedTemplate} />
									)}
							</Grid>
						</Tabs.Panel>
					))}
				</Tabs>}
			<Group position="right">
				<Button onClick={() => setNewTemplate({})} variant="light" leftIcon={<Plus size={18} />}>{dict.createTemplate}</Button>
			</Group>
		</Modal>
	);
}
