import React, { useEffect, useState } from "react";
import { Table } from "@mantine/core";
import { getUrl } from "utils/urlHelpers";
import dayjs from "dayjs";
import { emailToName } from "utils/helpers";
import TablePlaceHolder from "components/TablePlaceHolder";
import TemplateLogDetail from "./templateLogDetail";

export default function TemplateLogs({ dict, templateId }) {
	const [logs, setLogs] = useState(null);
	const [logUid, setLogUid] = useState(null);

	useEffect(() => {
		getUrl(`conversation/template/${templateId}`, setLogs);
	}, []);

	if (logUid) return <TemplateLogDetail dict={dict} logUid={logUid} setLogUid={setLogUid} />;

	return (
		<Table highlightOnHover>
			<tbody>
				{!logs
					? <TablePlaceHolder row={5} col={3} />
					: logs?.map(log =>
						<tr key={log.uid} style={{ cursor: "pointer" }} onClick={() => setLogUid(log.uid)}>
							<td valign="top">{dayjs(log.date).format("DD/MM/YYYY")}</td>
							<td valign="top">{emailToName(log.email)}</td>
							<td>{log.question}</td>
						</tr>
					)}
			</tbody>
		</Table>
	);
}