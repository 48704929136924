import { Indicator, Stack, Text, Tooltip } from "@mantine/core";
import React from "react";
import { FileText } from "tabler-icons-react";

export default function ChatFile({ file, removeFile }) {

	return (
		<Tooltip label={file.name} multiline openDelay={200} style={{ maxWidth: 300 }} >
			<Stack style={{ width: 48 }} spacing={0}>
				<Indicator color="gray" size={16} label="x" offset={4} onClick={removeFile} style={{ cursor: "pointer" }}>
					<FileText size={24} strokeWidth={1} style={{ marginLeft: 12 }} />
				</Indicator>
				<Text truncate="end" size="xs">{file.name}</Text>
			</Stack>
		</Tooltip>
	);
}