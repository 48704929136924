import { useEffect, useState } from "react";
import { getUrl } from "utils/urlHelpers";

export default function useTemplates() {
	const [templates, setTemplates] = useState();
	const [templateRights, setTemplateRights] = useState();
	const [savedTemplate, setSavedTemplate] = useState();
	const [deletedTemplate, setDeletedTemplate] = useState();

	useEffect(() => {
		loadTemplates();
		loadTemplatesRights();
	}, []);

	useEffect(() => {
		if (templates)
			sessionStorage.setItem("templates", JSON.stringify(templates));
	}, [templates]);


	const loadTemplates = () => {
		const savedTemplates = JSON.parse(sessionStorage.getItem("templates"));
		if (!savedTemplates)
			getUrl("template", setTemplates);
		else
			setTemplates(savedTemplates);
	};

	const loadTemplatesRights = () => {
		const savedTemplateRights = JSON.parse(sessionStorage.getItem("templateRights"));
		if (!savedTemplateRights)
			getUrl("templateUserRights", data => {
				setTemplateRights(data);
				sessionStorage.setItem("templateRights", JSON.stringify(data));
			});
		else
			setTemplateRights(savedTemplateRights);
	};

	useEffect(() => {
		if (!savedTemplate) return;
		setTemplates(oldTemplates => {
			const index = oldTemplates.findIndex(t => t.id === savedTemplate.id);
			if (index >= 0)
				oldTemplates[index] = savedTemplate;
			else
				oldTemplates.push(savedTemplate);

			oldTemplates.sort((a, b) => {
				if (a.category === null && b.category !== null) return -1;
				if (a.category !== null && b.category === null) return 1;
				if (a.category < b.category) return -1;
				if (a.category > b.category) return 1;
				return a.order - b.order;
			});
			return [...oldTemplates];
		});
	}, [savedTemplate]);

	useEffect(() => {
		if (!deletedTemplate) return;
		setTemplates(oldTemplates => {
			const index = oldTemplates.findIndex(t => t.id === deletedTemplate.id);
			if (index >= 0)
				oldTemplates.splice(index, 1);
			return [...oldTemplates];
		});

	}, [deletedTemplate]);

	return { templates, setSavedTemplate, setDeletedTemplate, templateRights };
}