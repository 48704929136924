import React, { useEffect, useState } from "react";
import { Button, Group, Paper, Select } from "@mantine/core";
import { useDictionary } from "i18n/dictionary";
import { getUrl } from "utils/urlHelpers";
import { DateInput } from "@mantine/dates";
import dayjs from "dayjs";
import Charts from "./Charts";

export default function ChartsContainer({ charts, setCharts }) {
	const dict = useDictionary("dashboard");
	const weekDay = dayjs().day();
	const lastMonday = dayjs().subtract(weekDay == 0 ? 6 : weekDay - 1, "day");
	const [from, setFrom] = useState(lastMonday.subtract(8, "weeks").toDate());
	const [to, setTo] = useState(lastMonday.subtract(1, "day").toDate());
	const [groupingOption, setGroupingOption] = useState("auto");

	useEffect(() => {
		if (Object.keys(dict).length) loadGraphs();
	}, [dict]);

	const loadGraphs = () => {
		const grouping = getGrouping();

		charts.forEach(chart => {
			const baseUrl = chart.templateId ? `template/${chart.templateId}/stats` : "chatStat";
			const url = `${baseUrl}/${chart.type}?from=${from ? dayjs(from).format("YYYY-MM-DD") : ""}&to=${to ? dayjs(to).format("YYYY-MM-DD") : ""}&grouping=${grouping || ""}`;
			getUrl(url, data => {
				setCharts(prev => prev.map(x => x.index == chart.index ? { ...x, data: formatDataDate(translateDataHeader(data), grouping) } : x));
			});
		});
	};
	const getGrouping = () => {
		if (groupingOption == "none") return null;
		const days = Math.round((to - from) / (1000 * 60 * 60 * 24));
		let grouping;
		if (!to || !from || days > 60)
			grouping = 1;
		else
			grouping = days > 10 ? 2 : 3;
		return grouping;
	};

	const translateDataHeader = data => {
		data[0].forEach((item, i) => {
			data[0][i] = dict[item] || item;
		});
		return data;
	};
	const formatDataDate = (data, grouping) => {
		data.forEach((item, i) => {
			if (i > 0) {
				if (/^\d/.test(item[0]) && dayjs(item[0]).isValid()) {
					let formattedDate;
					if (grouping == null)
						formattedDate = dict.total;
					else if (grouping == 1) {
						formattedDate = dayjs(item[0]).format("MMMM");
					} else if (grouping == 2) {
						formattedDate = `${dict.week} ${item[0].split(" ")[1]}`;
					} else {
						formattedDate = dayjs(item[0]).format("D MMM");
					}
					data[i][0] = formattedDate;
				} else {
					data[i][0] = item[0];
				}
			}
		});
		return data;
	};

	return (
		<>
			<Paper px="lg" pt={4} pb="sm" mx="md" my="xs" shadow="md">
				<Group align="flex-end">
					<DateInput label={dict.from} value={from} onChange={setFrom} clearable valueFormat="DD/MM/YYYY" maxDate={to || new Date()} sx={{ width: 120 }} />
					<DateInput label={dict.to} value={to} onChange={setTo} clearable valueFormat="DD/MM/YYYY" minDate={from} maxDate={new Date()} sx={{ width: 120 }} />
					<Select label={dict.grouping} value={groupingOption} onChange={setGroupingOption} sx={{ width: 120 }}
						data={[{ value: "auto", label: dict.groupingAuto }, { value: "none", label: dict.groupingNone }]} />
					<Button onClick={loadGraphs}>{dict.apply}</Button>
				</Group>
			</Paper>
			<Charts dict={dict} charts={charts} />
		</>
	);
}