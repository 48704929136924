import React from "react";
import { Button, Menu, Text } from "@mantine/core";
import { Logout, Settings, UserCircle } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";
import { emailToName } from "utils/helpers";

export default function HistoryUser({ dict, setLoadedConversationUid, closeHistory, logout }) {
	const userIsAdmin = JSON.parse(localStorage.getItem("userEmail")).admin;
	const navigate = useNavigate();

	const openAdmin = () => {
		navigate("./admin");
		closeHistory();
	};

	return (
		<Menu shadow="md" width={200}>
			<Menu.Target>
				<Button variant="outline" color="gray" mr="xl" onClick={() => { setLoadedConversationUid(null); closeHistory(); }} leftIcon={<UserCircle size={18} />}>
					<Text>{emailToName(JSON.parse(localStorage.getItem("userEmail")).email).split(" ")[0]}</Text>
				</Button>
			</Menu.Target>
			<Menu.Dropdown>
				{userIsAdmin && <>
					<Menu.Item icon={<Settings size={14} />} onClick={openAdmin}>{dict.admin}</Menu.Item>
					<Menu.Divider />
				</>}
				<Menu.Item icon={<Logout size={14} />} onClick={logout}>{dict.logout}</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
}