import React, { useEffect, useState } from "react";
import { Notifications } from "@mantine/notifications";
import { Burger, Drawer, MantineProvider } from "@mantine/core";
import { useMediaQuery, useDisclosure } from "@mantine/hooks";
import { useDictionary } from "i18n/dictionary";
import Login from "pages/login";
import History from "pages/history/history";
import Chat from "pages/chat/chat";
import { Outlet } from "react-router-dom";

function App() {
	const [token, setToken] = useState(null);
	const dict = useDictionary();
	const [loadedConversationUid, setLoadedConversationUid] = useState(null);
	const [deletedConversationUid, setDeletedConversationUid] = useState(null);
	const [newConversation, setNewConversation] = useState(null);
	const [historyOpened, { open: openHistory, close: closeHistory }] = useDisclosure(false);

	// Check if the user is logged in
	useEffect(() => {
		const user = JSON.parse(sessionStorage.getItem("userLogged"));
		const expiryDate = new Date(user?.expire);
		if (user?.token && expiryDate > new Date())
			setToken(user.token);

		window.addEventListener("logout", () => setToken(null));
	}, []);

	useEffect(() => {
		closeHistory();
	}, [loadedConversationUid, deletedConversationUid, newConversation]);

	// Remove the token from the session storage if within 1 minute of expiring
	useEffect(() => {
		const interval = setInterval(() => {
			const user = JSON.parse(sessionStorage.getItem("userLogged"));
			const expiryDate = new Date(user?.expire);
			if (user?.token && expiryDate < new Date(new Date().getTime() + 60000))
				setToken(null);

		}, 60000);
		return () => clearInterval(interval);
	}, []);

	const logout = () => {
		setToken(null);
		sessionStorage.clear();
	};

	const largeScreen = useMediaQuery("(min-width: 800px)");

	return (
		<MantineProvider theme={{ colorScheme: "dark" }} withNormalizeCSS withGlobalStyles>
			<Notifications />
			{!token && <Login setToken={setToken} />}
			{token?.length > 0 &&
				<div style={{ display: "flex", height: "100%" }}>
					{largeScreen && <div style={{ width: 200 }}>
						<History dict={dict} setLoadedConversationUid={setLoadedConversationUid} newConversation={newConversation}
							setDeletedConversationUid={setDeletedConversationUid} closeHistory={closeHistory} logout={logout} />
					</div>
					}
					<div style={{ flexGrow: 1 }}>
						{!largeScreen && <>
							<Drawer opened={historyOpened} onClose={closeHistory} title="Authentication">
								<History dict={dict} setLoadedConversationUid={setLoadedConversationUid} newConversation={newConversation}
									setDeletedConversationUid={setDeletedConversationUid} closeHistory={closeHistory} />
							</Drawer>
							<Burger opened={historyOpened} onClick={openHistory} />
						</>
						}
						<Chat dict={dict} loadedConversationUid={loadedConversationUid} setNewConversation={setNewConversation}
							deletedConversationUid={deletedConversationUid} />
					</div>
					<Outlet />
				</div>
			}
		</MantineProvider>
	);
}

export default App;
