import React, { useState, useEffect } from "react";
import { ActionIcon, Button, Group, Modal, Text, useMantineTheme } from "@mantine/core";
import { emailToName } from "utils/helpers";
import { AlertTriangle, ArrowLeft, HeartMinus, HeartPlus, Send } from "tabler-icons-react";
import { fetchUrl } from "utils/urlHelpers";
import TemplateInput from "./templateInput";
import ChatFiles from "pages/chat/chatFiles";
import { useNavigate } from "react-router-dom";

export default function TemplateContent({ dict, templateId, applyTemplate, favourites, setFavourites, largeScreen }) {
	const theme = useMantineTheme();
	const [prompt, setPrompt] = useState(null);
	const [files, setFiles] = useState([]);
	const userParam = JSON.parse(localStorage.getItem("userEmail"));
	const [values, setValues] = useState(
		{ "Enter your name": emailToName(userParam.email).split(" ")[0], "Company name": userParam.company }
	);
	const [favouriteUpdating, setFavouriteUpdating] = useState(null);
	const [error, setError] = useState(null);
	const [template, setTemplate] = useState(null);
	const [inputs, setInputs] = useState(null);

	const navigate = useNavigate();

	useEffect(() => {
		fetchUrl(`template/${templateId}`, "GET", null, null, data => {
			const newInputs = data.prompt.match(/{([^}]+)}/g)
				?.map(match => match.slice(1, -1)) || [];
			const newPrompt = updatePrompt(data);
			if (newInputs.length === 0 && !data.fileRequired)
				checkAndApplyTemplate(data, newPrompt, newInputs);
			setInputs(newInputs);
			setTemplate(data);
			document.title = `BigGPT - ${data.name}`;
		});
	}, []);

	const checkAndApplyTemplate = (loadedTemplate, updatedPrompt, loadedInputs) => {
		if (loadedTemplate.fileRequired && files.length === 0 && loadedInputs.length === 0)
			return setError(dict.fileShouldBeAttached);
		if (loadedInputs.some(x => !values[x]))
			return setError(dict.templateFieldRequired);
		applyTemplate(updatedPrompt, loadedTemplate.id, files);
	};

	const updatePrompt = (template, label, value) => {
		const newValues = { ...values, [label]: value };
		setValues(newValues);
		let newPrompt = template.prompt;
		for (const [key, value] of Object.entries(newValues))
			newPrompt = newPrompt.replace(`{${key}}`, value);
		setPrompt(newPrompt);
		return newPrompt;
	};

	const isFavorite = favourites?.find(f => f.id === template?.id);

	const toggleFavourite = () => {
		if (isFavorite) {
			fetchUrl(`templateFavourite/${template.id}`, "DELETE", null, setFavouriteUpdating, () => {
				setFavourites(favourites.filter(f => f.id !== template.id));
			}, dict.favouriteRemoved);
		} else {
			fetchUrl(`templateFavourite/${template.id}`, "POST", null, setFavouriteUpdating, () => {
				setFavourites([...favourites, template]);
			}, dict.favouriteAdded);
		}
	};
	if (template) return (
		<Modal opened={true} size="70%" title={template.name} fullScreen={!largeScreen} closeOnClickOutside={false}
			onClose={() => navigate("..")} styles={{ title: { flex: 0.66 } }}>
			<Text my="lg">{template.intro}</Text>
			{inputs.map((x, index) => (
				<TemplateInput key={index} dict={dict} template={template} applyTemplate={applyTemplate}
					label={x} values={values} prompt={prompt} updatePrompt={updatePrompt} isFirst={index == 0} isLast={index == inputs.length - 1} />
			))}
			{template.fileRequired &&
				<ChatFiles dict={dict} files={files} setFiles={setFiles} />
			}
			<Group position="apart" mt="xl">
				<ActionIcon variant="filled" color="blue" onClick={toggleFavourite} loading={favouriteUpdating}>
					{isFavorite && <HeartMinus size={18} />}
					{!isFavorite && <HeartPlus size={18} />}
				</ActionIcon>
				{template.monitored &&
					<Group spacing={4}>
						<AlertTriangle size={18} color={theme.colors.red[8]} />
						<Text size="sm" color={theme.colors.red[8]}>{dict.monitored}</Text>
					</Group>
				}
				<Group>
					<Button variant="outline" onClick={() => navigate("/templates")} leftIcon={<ArrowLeft size={18} />}>{dict.back}</Button>
					<Button onClick={() => checkAndApplyTemplate(template, prompt, inputs)} leftIcon={<Send size={18} />}>{template.name}</Button>
				</Group>
			</Group>
			<Group position="right">
				<Text color={theme.colors.red[8]}>{error}</Text>
			</Group>
		</Modal>
	);
}