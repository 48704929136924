import { useEffect, useState } from "react";
import { objectToFormData } from "utils/helpers";
import { fetchUrl } from "utils/urlHelpers";

export default function useChat({ setNewConversation }) {
	const [templateId, setTemplateId] = useState(null);
	const [question, setQuestion] = useState("");
	const [conversationUid, setConversationUid] = useState(null);
	const [conversation, setConversation] = useState([]);
	const [loading, setLoading] = useState(null);
	const [model, setModel] = useState(localStorage.getItem("model") || "3");
	const [usingFunctions, setUsingFunctions] = useState(false);
	const [files, setFiles] = useState([]);

	useEffect(() => {
		if (model != "4")
			localStorage.setItem("model", model);
	}, [model]);

	const postponeLoginExpiryBy4Hours = () => {
		const user = JSON.parse(sessionStorage.getItem("userLogged"));
		user.expire = new Date((new Date()).getTime() + 4 * 60 * 60000);
		sessionStorage.setItem("userLogged", JSON.stringify(user));
	};

	const askQuestion = (e, newQuestion, newConversation, newTemplateId, newFiles) => {
		e?.preventDefault();
		newTemplateId = newTemplateId || templateId;
		newQuestion = newQuestion || question;
		newConversation = newConversation || conversation;
		newFiles = newFiles || files;
		
		if (newQuestion.length === 0) return;

		const data = {
			conversationUid, question: newQuestion, templateId: newTemplateId, model, usingFunctions, files: newFiles
		};
		setConversation([...newConversation, { from: "user", question: newQuestion, files: [...newFiles] }, { from: "bot", loading: true }]);
		setTemplateId(newTemplateId);
		setFiles([]);
		fetchUrl("chatHistory/", "POST", objectToFormData(data), setLoading, response => {
			postponeLoginExpiryBy4Hours();
			setConversationUid(response.conversationUid);
			setConversation([
				...newConversation,
				{ from: "user", question: newQuestion, id: response.id, files: [...newFiles] },
				{ from: "bot", answer: response.answer, id: response.id, hasAssistantFiles: response.hasAssistantFiles }
			]);
			if (response.conversationUid != conversationUid)
				setNewConversation(response);	// This is used to update the conversation list in the left panel
		});
	};

	const regenerate = () => {
		fetchUrl(`chatHistory/${conversation[conversation.length - 1].id}`, "DELETE", null, null, () => {
			const newQuestion = conversation[conversation.length - 2].question;
			const newConversation = conversation.slice(0, -2);
			askQuestion(null, newQuestion, newConversation);
		});
	};
	const editQuestion = (conversationId, newQuestion) => {
		fetchUrl(`conversation/${conversationUid}?fromId=${conversationId}`, "DELETE", null, null, () => {
			const newConversation = conversation.slice(0, conversation.findIndex(c => c.id === conversationId));
			askQuestion(null, newQuestion, newConversation);
		});
	};

	// Split QAs into a conversation array [Q, A, Q, A, ...]
	const splitLoadedQAs = qas => {
		return qas.reduce((acc, obj) => {
			const { question, answer, ...rest } = obj;
			return acc.concat([
				{ from: "user", question, ...rest },
				{ from: "bot", answer, ...rest }
			]);
		}, []);
	};

	return {
		conversation, setConversation, setTemplateId, question, setQuestion, model, setModel, usingFunctions, setUsingFunctions,
		conversationUid, setConversationUid, loading, askQuestion, regenerate, editQuestion, splitLoadedQAs, files, setFiles
	};
}