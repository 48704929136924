import React, { useState } from "react";
import { Modal, Table } from "@mantine/core";
import { ChartBar, Lock } from "tabler-icons-react";
import ChartsContainer from "pages/charts/ChartsContainer";

export default function AdminTemplatesTable({ dict, templates, searchTerm, setTemplate, bigChangeAccount }) {
	const [charts, setCharts] = useState();

	const openStats = template => {
		setCharts([{ index: 7, type: 1, chartType: "ColumnChart", templateId: template.id }]);
	};

	let prevCategory;

	const rows = templates?.filter(template =>
		template.category?.toLowerCase().includes(searchTerm.toLowerCase()) ||
		template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
		template.intro.toLowerCase().includes(searchTerm.toLowerCase())
	).flatMap(template => {
		const rows = [];

		if (prevCategory !== template.category) {
			prevCategory = template.category;
			rows.push(
				<tr key={template.category}>
					<td colSpan="3" style={{ fontWeight: "bold" }}>{template.category || dict.miscellaneous}</td>
				</tr>
			);
		}

		rows.push(
			<tr key={template.id} style={{ cursor: (bigChangeAccount || !template.public) ? "pointer" : undefined }}>
				<td>{!bigChangeAccount && template.public && <Lock size={18} strokeWidth={1.5} />}</td>
				<td onClick={() => (bigChangeAccount || !template.public) && setTemplate(template)}>{template.name}</td>
				<td onClick={() => (bigChangeAccount || !template.public) && setTemplate(template)}>{template.intro}</td>
				<td><ChartBar size={18} strokeWidth={1.5} onClick={() => openStats(template)} /></td>
			</tr>
		);

		return rows;
	});

	return (
		<>
			<Table highlightOnHover>
				<thead>
					<tr>
						<th></th>
						<th>{dict.name}</th>
						<th>{dict.description}</th>
						<th></th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</Table>
			{charts &&
				<Modal opened onClose={() => setCharts(null)} size={700} withCloseButton={false} trapFocus={false}>
					<ChartsContainer charts={charts} setCharts={setCharts} />
				</Modal>
			}
		</>
	);
}