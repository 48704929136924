import React, { useRef } from "react";
import { FileButton, Group, ActionIcon, Button } from "@mantine/core";
import { Paperclip } from "tabler-icons-react";
import ChatFile from "./chatFile";

export default function ChatFiles({ dict, files, setFiles, compact }) {
	const resetRef = useRef(null);

	const addFiles = addedFiles => {
		setFiles([...files, ...addedFiles]);
		resetRef.current?.();
	};
	const removeFile = index => {
		setFiles(files.filter((_, i) => i !== index));
	};

	return (
		<Group justify="center">
			{files.map((file, index) => (
				<ChatFile key={index} file={file} removeFile={() => removeFile(index)} />
			))}
			<FileButton onChange={addFiles} resetRef={resetRef} multiple variant="outline"
				accept=".c,.cpp,.csv,.docx,.html,.java,.json,.md,.pdf,.php,.pptx,.py,.rb,.tex,.txt,.css,.js,.tar,.ts,.xlsx,.xml,.zip">
				{props => (compact
					? <ActionIcon {...props}>
						<Paperclip size={18} />
					</ActionIcon>
					: <Button {...props} leftIcon={<Paperclip size={18} />}>
						{dict.attachFile}
					</Button>
				)}
			</FileButton>
		</Group>
	);
}