import { Button, Group, Input, Stack } from "@mantine/core";
import useTemplates from "hooks/useTemplates";
import React, { useState } from "react";
import Template from "../../templateEdit/template";
import { FileImport, Plus } from "tabler-icons-react";
import AdminTemplateLibrary from "./adminTemplateLibrary";
import AdminTemplatesTable from "./adminTemplatesTable";

export default function AdminTemplates({ dict }) {
	const { templates, setSavedTemplate, setDeletedTemplate } = useTemplates();
	const [template, setTemplate] = useState();
	const [libraryOpened, setLibraryOpened] = useState();
	const [searchTerm, setSearchTerm] = useState("");

	const bigChangeAccount = JSON.parse(localStorage.getItem("userEmail")).company == "BigChange";

	if (template)
		return <Template dict={dict} template={template} setTemplate={setTemplate} setSavedTemplate={setSavedTemplate} setDeletedTemplate={setDeletedTemplate} />;

	if (libraryOpened)
		return <AdminTemplateLibrary dict={dict} setLibraryOpened={setLibraryOpened} templates={templates} setSavedTemplate={setSavedTemplate} setDeletedTemplate={setDeletedTemplate} />;

	if (templates) return (
		<Stack>
			<Group position="apart">
				<Group position="Left">
					<Input placeholder={dict.search} value={searchTerm} onChange={e => setSearchTerm(e.currentTarget.value)} autoFocus />
				</Group>
				<Group position="right">
					<Button leftIcon={<FileImport />} onClick={() => setLibraryOpened(true)}>{dict.importTemplate}</Button>
					<Button leftIcon={<Plus />} onClick={() => setTemplate({})}>{dict.createTemplate}</Button>
				</Group>
			</Group>
			<AdminTemplatesTable dict={dict} templates={templates} searchTerm={searchTerm} setTemplate={setTemplate} bigChangeAccount={bigChangeAccount} />
		</Stack>
	);
}