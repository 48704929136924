import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Admin from "pages/admin/admin";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<App />}>
				<Route path="admin" element={<Admin />} />
				<Route path="admin/:tabValue" element={<Admin />} />
			</Route>
			<Route path="/templates" element={<App />} />
			<Route path="/templates/:templateId" element={<App />} />
		</Routes>
	</BrowserRouter>
);