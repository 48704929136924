import React, { useEffect, useRef, useState, } from "react";
import { Button, Group, ScrollArea, Select } from "@mantine/core";
import { PlayerTrackNext, Refresh, Search } from "tabler-icons-react";
import { getUrl } from "utils/urlHelpers";
import ConversationMessages from "./conversationMessages";
import ConversationEmpty from "./conversationEmpty";
import ConversationFeedback from "./conversationFeedback";

export default function Conversation({ dict, conversation, txtQuestion, regenerate, askQuestion, editQuestion, largeScreen, usingFunctions }) {
	const scrollArea = useRef(null);
	const [favourites, setFavourites] = useState(null);

	useEffect(() => {
		getUrl("templateFavourite", setFavourites);
	}, []);

	useEffect(() => {
		scrollArea.current?.scrollTo({ top: scrollArea.current.scrollHeight, behavior: "smooth" });
	}, [conversation, txtQuestion?.current?.clientHeight, txtQuestion?.current?.scrollHeight]);

	const extractNumberedList = str => {
		const matches = str?.match(/\b\d+\.\s[^:\n]*/g) || [];
		return matches.map(match => {
			return { value: match, label: (match.length > 30 ? match.slice(0, 30) + "..." : match).replace("**", "") };
		});
	};

	const lastMessage = conversation[conversation.length - 1];

	return (
		<>
			{conversation.length === 0
				? <ConversationEmpty dict={dict} askQuestion={askQuestion} largeScreen={largeScreen} favourites={favourites} setFavourites={setFavourites} />
				: <ScrollArea viewportRef={scrollArea} sx={{ flexGrow: 1 }} pr="sm">
					<ConversationMessages dict={dict} conversation={conversation} editQuestion={editQuestion} largeScreen={largeScreen} />

					{lastMessage.from === "bot" && !lastMessage.loading &&
						<Group position="apart" my={8} ml={8}>
							{usingFunctions
								? <ConversationFeedback conversation={conversation} />
								: <span></span>
							}
							<Group position="right">
								<Button variant="outline" color="gray" onClick={regenerate} leftIcon={<Refresh size={18} />}>
									{dict.regenerate}
								</Button>
								{lastMessage.tooBigToFinish &&
									<Button variant="outline" color="red" onClick={e => askQuestion(e, dict.continue)} leftIcon={<PlayerTrackNext size={18} />}>
										{dict.continue}
									</Button>
								}
								{lastMessage.from === "bot" && extractNumberedList(lastMessage.answer).length > 1 &&
									<Select error placeholder={dict.sectionDetail} icon={<Search size={18} />}
										sx={{ width: 250, maxWidth: "100%" }}
										data={extractNumberedList(lastMessage.answer)}
										onChange={x => askQuestion(null, `${dict.elaborate} ${x}`)} />
								}
							</Group>
						</Group>
					}
				</ScrollArea>
			}
		</>
	);
}